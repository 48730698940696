<template>
  <section>
    <CContainer>
      <CForm @submit.stop.prevent="Save()">
        <CCard>
          <CCardHeader class="d-flex justify-content-between align-items-center">
            <h5 class="mb-0">{{ $t('Navigation.Products/' + Action) }}</h5>
            <div class="card-header-actions d-flex">
              <WidgetsLocales v-if="$store.state.user.permission.MutipleLocale" :NowChooseLanguage.sync="NowChooseLanguage"></WidgetsLocales>
            </div>
          </CCardHeader>
          <CCardBody>
            <CTabs fade addTabsWrapperClasses="tab-info" @update:activeTab="ChangeTab">
              <CTab :title="$t('Product/Detail.Info')" active>
                <CRow>
                  <CCol md="8">
                    <CInput
                        v-if="Action === 'Edit'"
                        :label="$t('Product/Detail.SeriesNum')"
                        v-model="Data.SeriesNum"
                        horizontal
                        disabled
                    />
                    <CInput
                        :label="$t('Product/Detail.CustomSeriesNum')"
                        v-model="Data.CustomSeriesNum"
                        horizontal
                    />
                    <template v-if="NowChooseLanguage === 'default'">
                      <CInput
                        :label="$t('Product/Detail.Name')"
                        v-model="Data.Name"
                        horizontal
                      />
                      <CInput
                        :label="$t('Product/Detail.OtherName')"
                        v-model="Data.OtherName"
                        horizontal
                      />
                    </template>
                    <template v-else>
                      <CInput
                        :label="$t('Product/Detail.Name')"
                        v-model="Data.LanguageData[NowChooseLanguage].Name"
                        horizontal
                      />
                      <CInput
                        :label="$t('Product/Detail.OtherName')"
                        v-model="Data.LanguageData[NowChooseLanguage].OtherName"
                        horizontal
                      />
                    </template>
                    <CInput
                      :label="$t('Product/Detail.Model')"
                      v-model="Data.Model"
                      horizontal
                    />
                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/Detail.Categories') }}
                      </CCol>
                      <CCol sm="9">
                        <multiselect
                            v-model="SelectCategories"
                            :options="CategoryList"
                            :optionHeight="34"
                            label="label"
                            track-by="value"
                            :placeholder="$t('Global.PleaseSelect')"
                            :selectLabel="$t('Global.SelectLabel')"
                            :selectedLabel="$t('Global.SelectedLabel')"
                            :deselectLabel="$t('Global.DeselectLabel')"
                            :multiple="true"
                            @input="SynchronizeCategories()"
                        >
                          <template #noOptions>
                            {{ $t('Global.NoOptions') }}
                          </template>
                        </multiselect>
                      </CCol>
                    </CRow>
                    <template v-if="NowChooseLanguage === 'default'">
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Product/Detail.Tags') }}
                        </CCol>
                        <CCol sm="9">
                          <multiselect
                            id="Tags"
                            v-model="Data.Tags"
                            :tag-placeholder="$t('Global.EnterToAdd')"
                            :placeholder="$t('Global.PleaseKeyIn')"
                            :deselectLabel="$t('Global.DeselectLabel')"
                            :selectedLabel="$t('Global.SelectedLabel')"
                            :options="Data.Tags"
                            :multiple="true"
                            :taggable="true"
                            @tag="AddTag">
                            <template #noOptions>
                              {{ $t('Global.NoOptions') }}
                            </template>
                          </multiselect>
                        </CCol>
                      </CRow>
                    </template>
                    <template v-else>
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Product/Detail.Tags') }}
                        </CCol>
                        <CCol sm="9">
                          <multiselect
                            id="Tags"
                            :key="NowChooseLanguage"
                            v-model="Data.LanguageData[NowChooseLanguage].Tags"
                            :tag-placeholder="$t('Global.EnterToAdd')"
                            :placeholder="$t('Global.PleaseKeyIn')"
                            :deselectLabel="$t('Global.DeselectLabel')"
                            :selectedLabel="$t('Global.SelectedLabel')"
                            :options="Data.LanguageData[NowChooseLanguage].Tags"
                            :multiple="true"
                            :taggable="true"
                            @remove="RemoveLocaleTag"
                            @tag="AddTag">
                            <template #noOptions>
                              {{ $t('Global.NoOptions') }}
                            </template>
                          </multiselect>
                        </CCol>
                      </CRow>
                    </template>
                  </CCol>
                  <CCol md="4">
                    <CRow>
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/Detail.OnlineTime') }}
                      </CCol>
                      <CCol sm="9">
                        <v-date-picker v-model="Data.OnlineTime" mode="dateTime" is24hr>
                          <template v-slot="{ inputValue, inputEvents }">
                            <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                              <template #append-content>
                                <CIcon name="cil-calendar"/>
                              </template>
                            </CInput>
                          </template>
                        </v-date-picker>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol tag="label" sm="3" class="col-form-label" for="OfflineTime">
                        {{ $t('Product/Detail.OfflineTime') }}
                      </CCol>
                      <CCol sm="9">
                        <v-date-picker v-model="Data.OfflineTime" mode="dateTime" is24hr>
                          <template v-slot="{ inputValue, inputEvents }">
                            <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                              <template #append-content>
                                <CIcon name="cil-calendar"/>
                              </template>
                            </CInput>
                          </template>
                        </v-date-picker>
                      </CCol>
                    </CRow>
                    <CInput
                        :label="$t('Product/Detail.Sort')"
                        v-model="Data.Sort"
                        type="number"
                        horizontal
                    />
                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/Detail.Status') }}
                      </CCol>
                      <CCol sm="9">
                        <CSwitch color="success" :checked.sync="ProductStatus"/>
                      </CCol>
                    </CRow>
                    <CRow v-if="Features.includes('ProductOptions')" form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/Detail.EnableOption') }}
                      </CCol>
                      <CCol sm="9">
                        <CSwitch color="success" :checked.sync="EnableOption"/>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Product/Detail.Property')">
                <CRow>
                  <CCol md="7">
                    <CSelect horizontal :label="$t('Product/Detail.PropertyType')" :options="PropertyTypeOptions" v-model="Data.Variable.PropertyType" :value.sync="Data.Variable.PropertyType" :placeholder="$t('Global.PleaseSelect')"/>
                    <CSelect v-if="Data.Variable.PropertyType === 'Virtual'" horizontal :label="$t('Product/Detail.PropertyVirtual')" :options="PropertyVirtualOptions" v-model="Data.Variable.PropertyVirtual" :value.sync="Data.Variable.PropertyVirtual" :placeholder="$t('Global.PleaseSelect')"/>
                    <CSelect horizontal :label="$t('Product/Detail.SaleMode')" :options="SaleModeOptions" v-model="Data.Variable.SaleMode" :value.sync="Data.Variable.SaleMode" :placeholder="$t('Global.PleaseSelect')"/>
                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/Detail.ServiceLocation') }}
                      </CCol>
                      <CCol sm="9">
                        <multiselect
                          id="ServiceLocation"
                          v-model="Data.ServiceLocation"
                          label="label"
                          track-by="value"
                          :tag-placeholder="$t('Global.EnterToAdd')"
                          :placeholder="$t('Product/Detail.PleaseKeyInLocation')"
                          :deselectLabel="$t('Global.DeselectLabel')"
                          :selectedLabel="$t('Global.SelectedLabel')"
                          :options="CountryOptions"
                          :multiple="true"
                          :taggable="true"
                          @tag="AddServiceLocation">
                          <template #noOptions>
                            {{ $t('Global.NoOptions') }}
                          </template>
                        </multiselect>
                        <small class="form-text text-muted w-100">若未選擇則預設值為可銷售地區將依照購物設定的「啟用商品區域購物」的設定值。</small>
                      </CCol>
                    </CRow>
                    <CInput
                      v-if="Data.Variable.SaleMode === 'ExternalLink'"
                      :label="$t('Product/Detail.ExternalLink')"
                      v-model="Data.Variable.ExternalLink"
                      horizontal
                    />
                    <template v-if="NowChooseLanguage === 'default'">
                      <CInput
                        :label="$t('Product/Detail.Brand')"
                        v-model="Data.Brand"
                        horizontal
                      />
                      <CInput
                        :label="$t('Product/Detail.HeaderText')"
                        v-model="Data.HeaderText"
                        horizontal
                      />
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Product/Detail.Features') }}
                        </CCol>
                        <CCol sm="9">
                          <CInput v-for="(Item, Index) in Data.Variable.FeatureText" type="text" :key="'FeatureText-' + Index" v-model="Item.Value">
                            <template #append>
                              <CButton color="light" @click="RemoveVariableArrayInput('FeatureText', Index)">
                                <CIcon class="my-0" name="cil-trash" size="sm"/>
                              </CButton>
                            </template>
                          </CInput>
                          <CButton color="info" size="sm" type="button" @click="AddVariableArrayInput('FeatureText')">
                            <CIcon name="cil-plus" class="mr-1" /> {{ $t('Global.Add') }}
                          </CButton>
                        </CCol>
                      </CRow>
                    </template>
                    <template v-else>
                      <CInput
                        :label="$t('Product/Detail.Brand')"
                        v-model="Data.LanguageData[NowChooseLanguage].Brand"
                        horizontal
                      />
                      <CInput
                        :label="$t('Product/Detail.HeaderText')"
                        v-model="Data.LanguageData[NowChooseLanguage].HeaderText"
                        horizontal
                      />
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Product/Detail.Features') }}
                        </CCol>
                        <CCol sm="9">
                          <CInput v-for="(Item, Index) in Data.LanguageData[NowChooseLanguage]['Variable-FeatureText']" type="text" :key="'FeatureText-' + Index" v-model="Item.Value">
                            <template #append>
                              <CButton color="light" @click="RemoveVariableArrayInput('FeatureText', Index, NowChooseLanguage)">
                                <CIcon class="my-0" name="cil-trash" size="sm"/>
                              </CButton>
                            </template>
                          </CInput>
                          <CButton color="info" size="sm" type="button" @click="AddVariableArrayInput('FeatureText', NowChooseLanguage)">
                            <CIcon name="cil-plus" class="mr-1" /> {{ $t('Global.Add') }}
                          </CButton>
                        </CCol>
                      </CRow>
                    </template>
                  </CCol>
                  <CCol md="5">
                    <template v-if="Data.Variable.PropertyType === 'Real'">
                      <CInput
                        :label="$t('Product/Detail.Weight')"
                        v-model="Data.Variable.Weight"
                        type="number"
                        min="0.0000" step="0.0001"
                        horizontal>
                        <template #append-content>
                          <span>KG</span>
                        </template>
                      </CInput>
                      <CInput
                        :label="$t('Product/Detail.NetWeight')"
                        v-model="Data.Variable.NetWeight"
                        type="number"
                        min="0.0000" step="0.0001"
                        horizontal>
                        <template #append-content>
                          <span>KG</span>
                        </template>
                      </CInput>
                      <CInput
                        :label="$t('Product/Detail.Length')"
                        v-model="Data.Variable.Length"
                        type="number"
                        min="0.00" step="0.01"
                        horizontal>
                        <template #append-content>
                          <span>CM</span>
                        </template>
                      </CInput>
                      <CInput
                        :label="$t('Product/Detail.Width')"
                        v-model="Data.Variable.Width"
                        type="number"
                        min="0.00" step="0.01"
                        horizontal>
                        <template #append-content>
                          <span>CM</span>
                        </template>
                      </CInput>
                      <CInput
                        :label="$t('Product/Detail.Height')"
                        v-model="Data.Variable.Height"
                        type="number"
                        min="0.00" step="0.01"
                        horizontal>
                        <template #append-content>
                          <span>CM</span>
                        </template>
                      </CInput>
                      <CSelect horizontal :label="$t('Product/Detail.Temperature')" :options="TemperatureOptions"
                               v-model="Data.Variable.Temperature" :value.sync="Data.Variable.Temperature"
                               :placeholder="$t('Global.PleaseSelect')"
                               :description="$t('Product/Detail.TemperatureDescription')"
                      />
                    </template>
                    <template v-if="Data.Variable.SaleMode === 'PreOrder'">
                      <CRow>
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Product/Detail.AvailableShipmentDate') }}
                        </CCol>
                        <CCol sm="9">
                          <v-date-picker v-model="Data.Variable.AvailableShipmentDate" mode="date">
                            <template v-slot="{ inputValue, inputEvents }">
                              <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                                <template #append-content>
                                  <CIcon name="cil-calendar"/>
                                </template>
                              </CInput>
                            </template>
                          </v-date-picker>
                        </CCol>
                      </CRow>
                    </template>
                    <div v-if="Data.Variable.PropertyVirtual === 'Video'">
                      <h6 class="font-weight-bold mb-3">{{ $t('Video/Detail.ChooseVideoContent') }}</h6>
                      <CMedia v-if="Data.Variable.RelateContent" class="mb-2" :asideImageProps="{src: Data.Variable.RelateContent ? Data.Variable.RelateContent.Cover : ''}">
                        <h5 class="mt-0">{{ Data.Variable.RelateContent.Name }}</h5>
                        <p>
                          {{ $t('Video/Detail.Slug') }}: {{ Data.Variable.RelateContent.Slug }}<br>
                          {{ $t('Post/Detail.CreateTime') }}: {{ $dayjs(Data.Variable.RelateContent.CreateTime).format('YYYY-MM-DD HH:mm:ss') }}
                        </p>
                      </CMedia>
                      <CButton type="button" block color="danger" @click="OpenVideoContentModal">{{ $t('Video/Detail.ChooseVideoContent') }}</CButton>
                      <CButton type="button" block color="secondary" @click="ResetVideoContent()">{{ $t('Video/Detail.Reset') }}</CButton>
                    </div>
                    <div v-if="Data.Variable.PropertyVirtual === 'TransportTicket'">
                      <CInput
                        label="區間起點"
                        v-model="Data.Variable.StartLocation"
                        type="string"
                        horizontal>
                      </CInput>
                      <CInput
                        label="區間起點描述"
                        v-model="Data.Variable.StartLocationDescription"
                        type="string"
                        horizontal>
                      </CInput>
                      <CInput
                        label="區間終點"
                        v-model="Data.Variable.EndLocation"
                        type="string"
                        horizontal>
                      </CInput>
                      <CInput
                        label="區間終點描述"
                        v-model="Data.Variable.EndLocationDescription"
                        type="string"
                        horizontal>
                      </CInput>
                      <CButton type="button" block color="danger" @click="OpenTicketScheduleModal()">
                        <i class="fas fa-clock mr-1" />設定班次時刻
                      </CButton>
                    </div>
                  </CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Product/Detail.Amount')">
                <CAlert v-if="EnableOption" color="warning">
                  <CIcon name="cil-bell" /> 目前已開啟商品選項，實際價格將依照商品選項內的價格進行計算。<br>
                </CAlert>
                <CRow>
                  <CCol md="8">
                    <CInput
                      v-if="EnableOption"
                      :prepend="$store.state.currency + '$'"
                      label="目前價格區間"
                      :value="`${Data.Amount.RangeMin}~${Data.Amount.RangeMax}`"
                      :disabled="EnableOption"
                      horizontal />
                    <CInput
                        :prepend="$store.state.currency + '$'"
                        :label="$t('Product/Detail.Amount/Cost')"
                        v-model="Data.Amount.Cost"
                        type="number"
                        :disabled="EnableOption"
                        min="0.0000" step="0.0001"
                        horizontal />
                    <CInput
                        :prepend="$store.state.currency + '$'"
                        :label="$t('Product/Detail.Amount/Suggest')"
                        v-model="Data.Amount.Suggest"
                        type="number"
                        :disabled="EnableOption"
                        min="0.0000" step="0.0001"
                        horizontal />
                    <CInput
                        :prepend="$store.state.currency + '$'"
                        :label="$t('Product/Detail.Amount/Actual')"
                        v-model="Data.Amount.Actual"
                        type="number"
                        :disabled="EnableOption"
                        min="0.0000" step="0.0001"
                        horizontal />
                    <CInput
                        :prepend="$store.state.currency + '$'"
                        v-if="false"
                        :label="$t('Product/Detail.Amount/Member')"
                        v-model="Data.Amount.Member"
                        :disabled="EnableOption"
                        type="number"
                        horizontal>
                      <template #append>
                        <CButton type="button" color="dark" @click="Data.Amount.Member = Data.Amount.Actual">{{ $t('Product/Detail.TakeActualPrice') }}</CButton>
                      </template>
                    </CInput>
                  </CCol>
                  <CCol md="4">
                    <CInput
                      v-if="!EnableOption"
                        :label="$t('Product/Detail.AmountPercentCount')"
                        :append="$t('Product/Detail.Discount')"
                        v-model="AmountPercentCount"
                        horizontal
                        disabled
                    />
                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/Detail.EnableMemberAmount') }}
                      </CCol>
                      <CCol sm="9">
                        <CSwitch color="success" :checked.sync="EnableMemberAmount"/>
                      </CCol>
                    </CRow>
                    <CRow form class="form-group d-none">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/Detail.HiddenAmount') }}
                      </CCol>
                      <CCol sm="9">
                        <CSwitch color="success" :checked.sync="HiddenAmount"/>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Product/Detail.Images')">
                <template v-if="NowChooseLanguage === 'default'">
                  <h5>
                    {{ $t('Product/Detail.Carousel') }}
                  </h5>
                  <hr>
                  <Draggable
                    v-model="Data.Carousel"
                    class="row no-gutters mx-n1 mb-3"
                    tag="div"
                    v-bind="DragOptions"
                    @start="Drag = true"
                    @end="Drag = false"
                  >
                    <CCol v-for="(Data, Index) in Data.Carousel" :key="Index" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.URL" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Carousel', false, Index)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="secondary" size="sm" class="mx-1" @click="OpenMediaMeta('Carousel', Index)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="danger" size="sm" class="mx-1" @click="RemoveImage('Carousel', Index)">
                            <CIcon name="cil-trash" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Carousel')">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </Draggable>
                  <h5>{{ $t('Product/Detail.Cover') }}</h5>
                  <hr>
                  <CRow class="no-gutters mx-n1 mb-3">
                    <CCol v-if="Data.Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.Cover" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol v-if="!Data.Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                  <template v-if="Features.includes('OverViewImages')">
                    <h5>
                      {{ $t('Product/Detail.OverViewImages') }}
                    </h5>
                    <hr>
                    <Draggable
                      v-model="Data.OverViewImages"
                      class="row no-gutters mx-n1 mb-3"
                      tag="div"
                      v-bind="DragOptions"
                      @start="Drag = true"
                      @end="Drag = false"
                    >
                      <CCol v-for="(Data, Index) in Data.OverViewImages" :key="Index" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                        <div class="position-relative CarouselItem">
                          <img v-lazy="Data.URL" class="img-fluid" />
                          <div class="CarouselTools">
                            <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('OverViewImages', false, Index)">
                              <CIcon name="cil-pencil" />
                            </CButton>
                            <CButton color="secondary" size="sm" class="mx-1" @click="OpenMediaMeta('OverViewImages', Index)">
                              <CIcon name="cil-pencil" />
                            </CButton>
                            <CButton color="danger" size="sm" class="mx-1" @click="RemoveImage('OverViewImages', Index)">
                              <CIcon name="cil-trash" />
                            </CButton>
                          </div>
                        </div>
                      </CCol>
                      <CCol xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                        <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                          <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                          <div class="CarouselTools">
                            <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('OverViewImages')">
                              <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                            </CButton>
                          </div>
                        </div>
                      </CCol>
                    </Draggable>
                  </template>
                </template>
                <template v-else>
                  <h5>
                    {{ $t('Product/Detail.Carousel') }}
                  </h5>
                  <hr>
                  <Draggable
                    v-model="Data.LanguageData[NowChooseLanguage].Carousel"
                    class="row no-gutters mx-n1 mb-3"
                    tag="div"
                    v-bind="DragOptions"
                    @start="Drag = true"
                    @end="Drag = false"
                  >
                    <CCol v-for="(Data, Index) in Data.LanguageData[NowChooseLanguage].Carousel" :key="Index" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.URL" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Carousel', false, Index)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="secondary" size="sm" class="mx-1" @click="OpenMediaMeta('Carousel', Index)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                          <CButton color="danger" size="sm" class="mx-1" @click="RemoveImage('Carousel', Index)">
                            <CIcon name="cil-trash" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Carousel')">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </Draggable>
                  <h5>{{ $t('Product/Detail.Cover') }}</h5>
                  <hr>
                  <CRow class="no-gutters mx-n1 mb-3">
                    <CCol v-if="Data.LanguageData[NowChooseLanguage].Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="position-relative CarouselItem">
                        <img v-lazy="Data.LanguageData[NowChooseLanguage].Cover" class="img-fluid" />
                        <div class="CarouselTools">
                          <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-pencil" />
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                    <CCol v-if="!Data.LanguageData[NowChooseLanguage].Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                      <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                        <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                        <div class="CarouselTools">
                          <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                            <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                          </CButton>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                  <template v-if="Features.includes('OverViewImages')">
                    <h5>
                      {{ $t('Product/Detail.OverViewImages') }}
                    </h5>
                    <hr>
                    <Draggable
                      v-model="Data.LanguageData[NowChooseLanguage].OverViewImages"
                      class="row no-gutters mx-n1 mb-3"
                      tag="div"
                      v-bind="DragOptions"
                      @start="Drag = true"
                      @end="Drag = false"
                    >
                      <CCol v-for="(Data, Index) in Data.LanguageData[NowChooseLanguage].OverViewImages" :key="Index" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                        <div class="position-relative CarouselItem">
                          <img v-lazy="Data.URL" class="img-fluid" />
                          <div class="CarouselTools">
                            <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('OverViewImages', false, Index)">
                              <CIcon name="cil-pencil" />
                            </CButton>
                            <CButton color="secondary" size="sm" class="mx-1" @click="OpenMediaMeta('OverViewImages', Index)">
                              <CIcon name="cil-pencil" />
                            </CButton>
                            <CButton color="danger" size="sm" class="mx-1" @click="RemoveImage('OverViewImages', Index)">
                              <CIcon name="cil-trash" />
                            </CButton>
                          </div>
                        </div>
                      </CCol>
                      <CCol xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                        <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                          <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                          <div class="CarouselTools">
                            <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('OverViewImages')">
                              <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                            </CButton>
                          </div>
                        </div>
                      </CCol>
                    </Draggable>
                  </template>
                </template>
              </CTab>
              <CTab :title="$t('Product/Detail.Content')">
                <CRow>
                  <CCol md="8" lg="9">
                    <Editor api-key="bxtsuxyz45flv1nm7cp8mwh70tl0r1wwsmejyfsqffk7popz" :init="TinyMCE" v-model="CurrentContent" />
                    <template v-if="Debug">
                      <hr>
                      <div class="bg-light p-3">
                        <code v-if="EnableResponseContent && NowChooseDevice === 'Web'">{{ Data.ResponseData.Web.Content }}</code>
                        <code v-if="EnableResponseContent && NowChooseDevice === 'Mobile'">{{ Data.ResponseData.Mobile.Content }}</code>
                        <code v-if="!EnableResponseContent">{{ Data.Content }}</code>
                      </div>
                    </template>
                  </CCol>
                  <CCol md="4" lg="3">
                    <CRow form class="form-group">
                      <CCol tag="label" sm="4" class="col-form-label">
                        {{ $t('Product/Detail.EnableResponseContent') }}
                      </CCol>
                      <CCol sm="8">
                        <CSwitch color="success" :checked.sync="EnableResponseContent"/>
                      </CCol>
                    </CRow>
                    <template v-if="EnableResponseContent">
                      <CButton :color="(NowChooseDevice === 'Web' ? 'success' : 'light')" variant type="button" block @click="NowChooseDevice = 'Web'">
                        <CIcon name="cil-monitor" class="mr-1" /> {{ $t('Product/Detail.Web') }}
                      </CButton>
                      <CButton :color="(NowChooseDevice === 'Mobile' ? 'success' : 'light')" type="button" block @click="NowChooseDevice = 'Mobile'">
                        <CIcon name="cil-mobile" class="mr-1" /> {{ $t('Product/Detail.Mobile') }}
                      </CButton>
                    </template>
                  </CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Product/Detail.Unit')">
                <CRow>
                  <CCol md="8" lg="9">
                    <Editor api-key="bxtsuxyz45flv1nm7cp8mwh70tl0r1wwsmejyfsqffk7popz" :init="TinyMCE" v-model="CurrentUnit" />
                  </CCol>
                  <CCol md="4" lg="3">
                    <CRow form class="form-group">
                      <CCol tag="label" sm="4" class="col-form-label">
                        {{ $t('Product/Detail.EnableResponseContent') }}
                      </CCol>
                      <CCol sm="8">
                        <CSwitch color="success" :checked.sync="EnableResponseContent"/>
                      </CCol>
                    </CRow>
                    <template v-if="EnableResponseContent">
                      <CButton :color="(NowChooseDevice === 'Web' ? 'success' : 'light')" variant type="button" block @click="NowChooseDevice = 'Web'">
                        <CIcon name="cil-monitor" class="mr-1" /> {{ $t('Product/Detail.Web') }}
                      </CButton>
                      <CButton :color="(NowChooseDevice === 'Mobile' ? 'success' : 'light')" type="button" block @click="NowChooseDevice = 'Mobile'">
                        <CIcon name="cil-mobile" class="mr-1" /> {{ $t('Product/Detail.Mobile') }}
                      </CButton>
                    </template>
                  </CCol>
                </CRow>
              </CTab>
              <CTab v-if="EnableOption && Features.includes('ProductOptions')" :title="$t('Product/Detail.Option')">
                <CRow>
                  <CCol sm="6">
                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/Detail.EnableStock') }}
                      </CCol>
                      <CCol sm="9">
                        <CSwitch color="success" :checked.sync="EnableStock"/>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
                <Options
                  :SKUList="SKUList"
                  :ExistOptionField.sync="Data.OptionField"
                  :ExistOptions.sync="Data.Options"
                  :ExistOptionData.sync="Data.OptionData"
                  :EnableStock.sync="EnableStock"
                />
              </CTab>
              <CTab v-if="!EnableOption" :title="$t('Product/Detail.Stock')">
                <CAlert color="info">
                  <CIcon name="cil-bell" /> 需要設定SKU才能正常使用庫存，請前往「<b><a href="/products/stock/list" target="_blank" class="text-info">商品管理 > 庫存</a></b>」以新增庫存單位。
                </CAlert>
                <CRow>
                  <CCol md="8">
                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/Detail.EnableStock') }}
                      </CCol>
                      <CCol sm="9">
                        <CSwitch color="success" :checked.sync="EnableStock"/>
                      </CCol>
                    </CRow>
                    <template v-if="EnableStock">
                      <CRow form class="form-group">
                        <CCol tag="label" sm="3" class="col-form-label">
                          {{ $t('Product/Detail.Stock/SKU') }} <i v-c-tooltip="{content: $t('Product/Detail.Stock/SKUDescription'),placement: 'bottom'}" class="fas fa-question-circle text-info ml-1" />
                        </CCol>
                        <CCol sm="9">
                          <multiselect
                            id="SelectSKU"
                            v-model="CurrentSKU"
                            label="Name_SKU"
                            track-by="SKU"
                            :tag-placeholder="$t('Product/Stock.EnterToAdd')"
                            :placeholder="$t('Product/Stock.EnterToAdd')"
                            :deselectLabel="$t('Global.DeselectLabel')"
                            :selectedLabel="$t('Global.SelectedLabel')"
                            :options="SKUList"
                            :showNoResults="false"
                            :showNoOptions="false"
                            @select="ChangeSKU"
                          >
                            <template #noOptions>
                              {{ $t('Global.NoOptions') }}
                            </template>
                          </multiselect>
                        </CCol>
                      </CRow>
                      <CInput
                        :label="$t('Product/Detail.Stock/RealStock')"
                        v-model="Data.Stock.RealStock"
                        type="number"
                        :disabled="true"
                        horizontal />
                      <CInput
                        :label="$t('Product/Detail.Stock/SafeStock')"
                        v-model="Data.Stock.SafeStock"
                        type="number"
                        :disabled="true"
                        horizontal />
                      <CInput
                        :label="$t('Product/Detail.Stock/MaxStock')"
                        v-model="Data.Stock.MaxStock"
                        type="number"
                        horizontal />
                    </template>
                  </CCol>
                  <CCol md="4"></CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Product/Detail.SEO')">
                <CRow>
                  <CCol md="8">
                    <template v-if="NowChooseLanguage === 'default'">
                      <CInput
                        :label="$t('Product/Detail.SEO/Title')"
                        v-model="Data.SEO.Title"
                        horizontal
                      />
                      <CInput
                        :label="$t('Product/Detail.SEO/Keyword')"
                        v-model="Data.SEO.Keywords"
                        horizontal
                      />
                      <CInput
                        :label="$t('Product/Detail.SEO/Description')"
                        v-model="Data.SEO.Description"
                        horizontal
                      />
                      <CInput
                        :label="$t('Product/Detail.SEO/OG:Title')"
                        v-model="Data.SEO.OGTitle"
                        horizontal
                      />
                      <CInput
                        :label="$t('Product/Detail.SEO/OG:Description')"
                        v-model="Data.SEO.OGDescription"
                        horizontal
                      />
                      <CInput
                        :label="$t('Product/Detail.SEO/OG:Image')"
                        v-model="Data.SEO.OGImage"
                        horizontal
                      />
                    </template>
                    <template v-else>
                      <CInput
                        :label="$t('Post/Detail.SEO/Title')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.Title"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/Keyword')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.Keywords"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/Description')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.Description"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Title')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.OGTitle"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Description')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.OGDescription"
                        horizontal
                      />
                      <CInput
                        :label="$t('Post/Detail.SEO/OG:Image')"
                        v-model="Data.LanguageData[NowChooseLanguage].SEO.OGImage"
                        horizontal
                      />
                    </template>
                  </CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Product/Detail.Channel')">
                <h5 >
                  {{ $t('Product/Detail.ChannelOptions.GMC') }}
                </h5>
                <hr>
                <CRow>
                  <CCol md="7">
<!--                    <CSelect horizontal :label="$t('Product/Detail.GMC_GoogleProductCategory')" :options="GoogleCategories" v-model="Data.Variable.GMC_GoogleProductCategory" :value.sync="Data.Variable.GMC_GoogleProductCategory" :placeholder="$t('Global.PleaseSelect')"/>-->
                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/Detail.GMC_GoogleProductCategory') }}
                      </CCol>
                      <CCol sm="9">
                        {{ Data.Variable.GMC_GoogleProductCategory }}
                        <multiselect
                          id="Variable.GMC_GoogleProductCategory"
                          v-model="Data.Variable.GMC_GoogleProductCategory"
                          :searchable="true"
                          :close-on-select="true"
                          :show-labels="true"
                          :placeholder="$t('Global.SearchKeyword')"
                          :deselectLabel="$t('Global.DeselectLabel')"
                          :selectedLabel="$t('Global.SelectedLabel')"
                          :options="GoogleCategories"
                          :optionHeight="34"
                          label="label"
                          track-by="value"
                        >
                          <template #noOptions>
                            {{ $t('Global.NoOptions') }}
                          </template>
                        </multiselect>
                      </CCol>
                    </CRow>

                    <CRow form class="form-group">
                      <CCol tag="label" sm="3" class="col-form-label">
                        {{ $t('Product/Detail.GMC_AvailableCountry') }}
                      </CCol>
                      <CCol sm="9">
                        <multiselect
                          id="Variable.GMC_AvailableCountry"
                          v-model="Data.Variable.GMC_AvailableCountry"
                          :tag-placeholder="$t('Global.EnterToAdd')"
                          :placeholder="$t('Global.PleaseKeyIn')"
                          :deselectLabel="$t('Global.DeselectLabel')"
                          :selectedLabel="$t('Global.SelectedLabel')"
                          :options="CountryOptions"
                          :multiple="true"
                          :optionHeight="34"
                          label="label"
                          track-by="value"
                          @tag="AddTag"
                        >
                          <template #noOptions>
                            {{ $t('Global.NoOptions') }}
                          </template>
                        </multiselect>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CTab>
              <CTab :title="$t('Product/Detail.Setting')">
                <template v-if="(Object.keys(VariableExtend).length > 0)">
                  <template v-for="(Config, Variable) in VariableExtend">
                    <h5 :key="Variable+ '-title'">
                      {{ $t('Product/ExtendVariable.' + Variable) }}
                    </h5>
                    <hr :key="Variable + '-hr'">
                    <Editor v-if="Config.FormType === 'editor'" :key="Variable + '-content'" api-key="bxtsuxyz45flv1nm7cp8mwh70tl0r1wwsmejyfsqffk7popz" :init="TinyMCE" v-model="Data.Variable[Variable]" />
                    <CInput v-if="Config.FormType === 'text'" type="text" :key="Variable + '-content'" v-model="Data.Variable[Variable]" />
                    <CTextarea v-if="Config.FormType === 'textarea'" row="3" :key="Variable + '-content'" v-model="Data.Variable[Variable]" />
                    <template v-if="Config.FormType === 'array'">
                      <div class="mb-3" :key="Variable + '-content'">
                        <CInput v-for="(Data, Index) in Data.Variable[Variable]" type="text" :key="'Array-' + Variable + Index" v-model="Data.Value">
                          <template #append>
                            <CButton color="light" @click="RemoveVariableArrayInput(Variable, Index)">
                              <CIcon class="my-0" name="cil-trash" size="sm"/>
                            </CButton>
                          </template>
                        </CInput>
                        <CButton :key="Variable + '-btn'" color="info" size="sm" type="button" @click="AddVariableArrayInput(Variable)">
                          <CIcon name="cil-plus" class="mr-1" /> {{ $t('Global.Add') }}
                        </CButton>
                      </div>
                    </template>
                  </template>
                </template>
                <CAlert v-else color="warning">{{ $t('Product/Detail.NoSetting') }}</CAlert>
              </CTab>
            </CTabs>
          </CCardBody>
          <CCardFooter class="text-right">
            <CButton v-if="Action === 'Edit'" color="info" type="button" class="mr-2" @click="$router.push('/products/add')">
              {{ $t('Global.ContinueAdd') }}
            </CButton>
            <CButton color="success" type="submit">
              {{ Action === 'Edit' ? $t('Global.Update') : $t('Global.Add') }}
            </CButton>
          </CCardFooter>
        </CCard>
        <CElementCover v-if="(Loading === true)" :opacity="0.75">
          <CSpinner color="success"/>
        </CElementCover>
      </CForm>
    </CContainer>
    <MediaStore :Config="MediaStoreConfig" ref="MediaStore" @OK="SetImages(ChooseImageType)" />
    <MediaMeta :Config="MediaMetaConfig" @OK="SetMeta" />
    <input id="tinymce-image-input" type="text" class="d-none">
    <component :is="CurrentComponent" :Single="true" :Toggle.sync="Toggle" @ChooseVideoContent="ChooseVideoContent" :ProductData="Data" @Finish="SetSchedule" />
  </section>
</template>

<route>
{
  "meta": {
    "label": "編輯商品"
  }
}
</route>

<script>
import dayjs from 'dayjs'
import DataSchema from '@/schema/product/detail'
import Editor from '@tinymce/tinymce-vue'
import TinyMCESetting from '@/plugins/tinymce'

export default {
  name: 'ProductDetail',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect'),
    Draggable: () => import('vuedraggable'),
    MediaStore: () => import('@/components/public/Media'),
    MediaMeta: () => import('@/components/public/MediaMeta'),
    Options: () => import('@/components/product/Options'),
    WidgetsLocales: () => import('@/components/widgets/WidgetsLocales'),
    Editor
  },
  data () {
    return {
      Action: 'Edit',
      Data: {}, //主資料
      Features: [],

      SelectCategories: [], //格式化後的已選商品分類
      ProductStatus: false, //格式化後的商品狀態
      EnableOption: false, //格式化後的商品選項開關
      EnableStock: false, //格式化後的商品庫存啟用開關
      EnableMemberAmount: false, //格式化後的會員價啟用開關
      HiddenAmount: false, //格式化後的隱藏價格開關
      EnableResponseContent: false, //格式化後的響應式商品內容開關
      Debug: false,
      CategoryList: [],
      VariableList: [],
      SKUList: [],
      NowChooseDevice: 'Web',
      NowChooseLanguage: 'default',
      Loading: false,
      Drag: false,
      ChooseImageType: '',
      ChooseImageIndex: false,
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false,
        ChooseImageType: '',
      },
      MediaMetaConfig: {
        Display: false,
        ImageMeta: {}
      },
      CurrentSKU: {},
      VariableExtend: {},
      TinyMCE: {
        ...TinyMCESetting,
        file_picker_callback: (callback) => {
          this.OpenMediaStore('Editor', false)
          const input = document.getElementById('tinymce-image-input')
          input.addEventListener('change', (event) => {
            callback(event.target.value, {title: ''})
          })
        },
      },
      CurrentComponent: null,
      Toggle: false,
      GoogleCategories: [],

      TypeList: ['boolean', 'string', 'number', 'object', 'array'],
      VariableNoItems: {
        noResults: this.$t('Product/Detail.NoVariableItems'),
        noItems: this.$t('Product/Detail.NoVariableItems')
      }
    }
  },
  computed: {
    AmountPercentCount() {
      if (parseInt(this.Data.Amount.Suggest) === 0) {
        return this.$t('Product/Detail.Uncountable')
      } else if ((parseInt(this.Data.Amount.Actual) / parseInt(this.Data.Amount.Suggest) * 10 >= 10)) {
        return this.$t('Product/Detail.NoDiscount')
      }
      return parseInt((parseInt(this.Data.Amount.Actual) / parseInt(this.Data.Amount.Suggest) * 10))
    },
    PropertyTypeOptions() {
      return Object.keys(this.$t('Product/Detail.PropertyTypeOptions')).map(value => {
        return {
          label: this.$t('Product/Detail.PropertyTypeOptions.' + value),
          value
        }
      })
    },
    PropertyVirtualOptions() {
      return Object.keys(this.$t('Product/Detail.PropertyVirtualOptions')).filter(value => {
        switch (value) {
          case 'Video':
          case 'TransportTicket':
            return this.Features.includes('PropertyTypeVirtual' + value)
          default:
            return true
        }
      }).map(value => {
        return {
          label: this.$t('Product/Detail.PropertyVirtualOptions.' + value),
          value
        }
      })
    },
    SaleModeOptions() {
      return Object.keys(this.$t('Product/Detail.SaleModeOptions')).map(value => {
        return {
          label: this.$t('Product/Detail.SaleModeOptions.' + value),
          value
        }
      })
    },
    TemperatureOptions() {
      return Object.keys(this.$t('Logistics.TemperatureOptions')).map(value => {
        return {
          label: this.$t('Logistics.TemperatureOptions.' + value),
          value
        }
      })
    },
    CountryOptions () {
      return Object.keys(this.$t('Logistics.CountryOptions')).filter(option => option !== 'TW-MAIN' && option !== 'TW-OUTLYING').map(option => {
        return {
          value: option,
          label: this.$t('Logistics.CountryOptions')[option]
        }
      })
    },
    DragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    CurrentContent: {
      get () {
        if (this.NowChooseLanguage !== 'default') {
          if (this.Data.LanguageData[this.NowChooseLanguage]) {
            if (this.EnableResponseContent) {
              switch (this.NowChooseDevice) {
                case 'Web':
                case 'Mobile':
                  if (this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice]) {
                    return this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice].Content
                  }
              }
            }
            return this.Data.LanguageData[this.NowChooseLanguage].Content
          }
        }
        if (this.EnableResponseContent) {
          switch (this.NowChooseDevice) {
            case 'Web':
            case 'Mobile':
              if (this.Data.ResponseData[this.NowChooseDevice]) {
                return this.Data.ResponseData[this.NowChooseDevice].Content
              }
          }
        }
        return this.Data.Content
      },
      set (value) {
        if (this.NowChooseLanguage !== 'default') {
          if (this.Data.LanguageData[this.NowChooseLanguage]) {
            if (this.EnableResponseContent) {
              switch (this.NowChooseDevice) {
                case 'Web':
                case 'Mobile':
                  if (this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice]) {
                    this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice].Content = value
                  }
              }
            } else {
              this.Data.LanguageData[this.NowChooseLanguage].Content = value
            }
          }
        } else {
          if (this.EnableResponseContent) {
            switch (this.NowChooseDevice) {
              case 'Web':
              case 'Mobile':
                if (this.Data.ResponseData[this.NowChooseDevice]) {
                  this.Data.ResponseData[this.NowChooseDevice].Content = value
                }
            }
          } else {
            this.Data.Content = value
          }
        }
      }
    },
    CurrentUnit: {
      get () {
        if (this.NowChooseLanguage !== 'default') {
          if (this.Data.LanguageData[this.NowChooseLanguage]) {
            if (this.EnableResponseContent) {
              switch (this.NowChooseDevice) {
                case 'Web':
                case 'Mobile':
                  if (this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice]) {
                    return this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice].Unit
                  }
              }
            }
            return this.Data.LanguageData[this.NowChooseLanguage].Unit
          }
        }
        if (this.EnableResponseContent) {
          switch (this.NowChooseDevice) {
            case 'Web':
            case 'Mobile':
              if (this.Data.ResponseData[this.NowChooseDevice]) {
                return this.Data.ResponseData[this.NowChooseDevice].Unit
              }
          }
        }
        return this.Data.Unit
      },
      set (value) {
        if (this.NowChooseLanguage !== 'default') {
          if (this.Data.LanguageData[this.NowChooseLanguage]) {
            if (this.EnableResponseContent) {
              switch (this.NowChooseDevice) {
                case 'Web':
                case 'Mobile':
                  if (this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice]) {
                    this.Data.LanguageData[this.NowChooseLanguage].ResponseData[this.NowChooseDevice].Unit = value
                  }
              }
            } else {
              this.Data.LanguageData[this.NowChooseLanguage].Unit = value
            }
          }
        } else {
          if (this.EnableResponseContent) {
            switch (this.NowChooseDevice) {
              case 'Web':
              case 'Mobile':
                if (this.Data.ResponseData[this.NowChooseDevice]) {
                  this.Data.ResponseData[this.NowChooseDevice].Unit = value
                }
            }
          } else {
            this.Data.Unit = value
          }
        }
      }
    }
  },
  created() {
    this.$set(this.$data, 'Data', Object.assign({}, DataSchema))
    this.Action = (this.$route.name === 'products-detail-add' ? 'Add' : 'Edit')
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: 'error'
      })
    })
  },
  methods: {
    Init() {
      const Permission = JSON.parse(localStorage.getItem('Permission')) || {}
      this.Features = Permission.Features.Products ? Permission.Features.Products.Add : []
      this.EnableMemberAmount = Permission.Permission.Organization.Member.includes('W')
      const Functions = [
        this.GetCategories(),
        this.GetSKUList(),
        this.GetExtendData()
      ]
      if (this.Action === 'Edit') Functions.push(this.GetData())
      return Promise.all(Functions).then(() => {
        //詳細資料處理
        this.HandleExtendData()
        if (this.Action === 'Edit') {
          this.HandleDetailData()
        }
        this.InitLocaleData()
        return true
      }).catch((err) => {
        console.error(err)
        throw err
      })
    },
    Save() {
      //拆分動作
      let Action
      switch (this.Action) {
        case 'Edit':
          Action = this.Edit()
          break
        case 'Add':
          Action = this.Add()
          break
        default:
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Product/SaveFail-1'),
            type: 'error'
          })
          return
      }
      this.Loading = true
      Action.then((result) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: result.msg,
          type: 'success'
        })
        if (this.Action === 'Add') {
          this.$router.replace('/products/detail/' + result.data.SeriesNum)
        }
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error') + '[' + err.code  + ']',
          text: this.$t('Message.Product/SaveFail-2') + err.msg,
          type: 'error'
        })
      })
    },
    Add() {
      if (this.Data.Slug === '') {
        throw {
          code: 403,
          msg: this.$t('Message.Product/SlugEmpty')
        }
      }
      if (this.$store.state.user.permission.MutipleLocale) {
        this.SynchronizeDefaultLocaleData()
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/product/add',
        method: 'post',
        data: {
          ...this.Data,
          Status: this.ProductStatus ? 1 : 0,
          EnableOption: this.EnableOption ? 1 : 0,
          EnableStock: this.EnableStock ? 1 : 0,
          EnableMemberAmount: this.EnableMemberAmount ? 1 : 0,
          HiddenAmount: this.HiddenAmount ? 1 : 0,
          EnableResponseContent: this.EnableResponseContent ? 1 : 0,
          OnlineTime: dayjs(this.Data.OnlineTime).unix(),
          OfflineTime: dayjs(this.Data.OfflineTime).unix(),
        }
      }).then(({data}) => {
        return {
          ...data,
          msg: this.$t('Message.Product/AddSuccess')
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Edit() {
      if (this.Data.Slug === '') {
        throw {
          code: 403,
          msg: this.$t('Message.Product/SlugEmpty')
        }
      }
      if (this.$store.state.user.permission.MutipleLocale) {
        this.SynchronizeDefaultLocaleData()
      }
      const updateData = {
        ...this.Data,
        Status: this.ProductStatus ? 1 : 0,
        EnableOption: this.EnableOption ? 1 : 0,
        EnableStock: this.EnableStock ? 1 : 0,
        EnableMemberAmount: this.EnableMemberAmount ? 1 : 0,
        HiddenAmount: this.HiddenAmount ? 1 : 0,
        EnableResponseContent: this.EnableResponseContent ? 1 : 0,
        OnlineTime: dayjs(this.Data.OnlineTime).unix(),
        OfflineTime: dayjs(this.Data.OfflineTime).unix()
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/product/edit',
        method: 'post',
        data: {
          seriesNum: updateData.SeriesNum,
          updateData
        }
      }).then(() => {
        this.GetData()
        return {
          msg: this.$t('Message.Product/EditSuccess')
        }
      }).catch((err) => {
        throw err
      })
    },
    GetData() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/product/detail',
        method: 'get',
        params: {
          seriesNum: this.$route.params.id
        }
      }).then(({data}) => {
        this.Loading = false
        this.Data = data.detail
        this.$set(this.Data, 'OptionData', data.detail.OptionData)
        this.$set(this.Data, 'Options', data.detail.Options)
        this.$set(this.Data, 'OptionField', data.detail.OptionField)
        if (!this.Data.Variable) this.$set(this.Data, 'Variable', {})
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetExtendData() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/product/extend',
        method: 'get'
      }).then(({data}) => {
        this.Loading = false
        this.VariableExtend = data
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetCategories () {
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/category/availableList',
          Data: {}
        }
      }).then(({data}) => {
        this.CategoryList = data.Data.map((category) => {
          return { label: category.Name, value: category.Slug }
        })
      }).catch((err) => {
        throw err
      })
    },
    HandleExtendData() {
      Object.keys(this.VariableExtend).forEach((Variable) => {
        if (!this.Data.Variable[Variable]) this.$set(this.Data.Variable, Variable, '')
      })
    },
    HandleDetailData() {
      this.SelectCategories = this.CategoryList.filter((data) => {
        return this.Data.Categories.includes(data.value)
      })
      if (this.Data.Variable) {
        this.VariableList = Object.keys(this.Data.Variable).map((data) => {
          let Value,
              Type = typeof this.Data.Variable[data]
          switch (typeof this.Data.Variable[data]) {
            case 'boolean':
            case 'string':
            case 'undefined':
            case 'number':
              Value = this.Data.Variable[data]
              break
            case 'object':
              Value = JSON.stringify(this.Data.Variable[data])
              if (Array.isArray(this.Data.Variable[data])) {
                Type = 'array'
              }
              break
          }
          return {
            Index: data,
            Value,
            Type
          }
        })
      }

      this.ProductStatus = (this.Data.Status === 1)
      this.EnableOption = (this.Data.EnableOption === 1)
      this.EnableStock = (this.Data.EnableStock === 1)
      this.EnableMemberAmount = (this.Data.EnableMemberAmount === 1)
      this.HiddenAmount = (this.Data.HiddenAmount === 1)
      this.EnableResponseContent = (this.Data.EnableResponseContent === 1)

      if (this.Data.EnableStock === 1 && this.Data.Stock.SKU) {
        this.CurrentSKU = this.SKUList.find(item => item.SKU === this.Data.Stock.SKU)
      }
    },
    HandleVariable() {
      this.VariableList.forEach((data) => {
        this.Data.Variable[data.Index] = data.Value
      })
    },
    SynchronizeCategories() {
      this.Data.Categories = this.SelectCategories.map(data => {
        return data.value
      })
    },
    AddTag (newTag, id) {
      if (id && id.split('.').length > 1) {
        this.Data[id.split('.')[0]][id.split('.')[1]].push(newTag)
        return true
      }
      this.Data[id].push(newTag)
      if (this.NowChooseLanguage !== 'default') {
        this.Data.LanguageData[this.NowChooseLanguage][id].push(newTag)
      }
    },
    AddServiceLocation (newTag, id) {
      if (id && id.split('.').length > 1) {
        this.Data[id.split('.')[0]][id.split('.')[1]].push(newTag)
        return true
      }
      this.Data[id].push(newTag)
    },
    RemoveLocaleTag (removedOption) {
      this.Data.Tags.splice(this.Data.Tags.indexOf(removedOption), 1)
    },
    AddVariable() {
      this.VariableList.push({
        Index: '',
        Value: '',
        Type: 'string'
      })
      this.HandleVariable()
    },
    RemoveVariable(index) {
      this.VariableList.splice(index, 1)
      this.HandleVariable()
    },
    AddVariableArrayInput(variable, locale) {
      if (locale) {
        if (!this.Data.LanguageData[locale][`Variable-${variable}`]) {
          this.$set(this.Data.LanguageData[locale], `Variable-${variable}`, [])
        }
        this.Data.LanguageData[locale][`Variable-${variable}`].push({
          Value: ''
        })
      } else {
        if (!this.Data.Variable[variable]) {
          this.$set(this.Data.Variable, variable, [])
        }
        this.Data.Variable[variable].push({
          Value: ''
        })
      }
    },
    RemoveVariableArrayInput(variable, index, locale) {
      if (locale) {
        this.Data.LanguageData[locale][`Variable-${variable}`].splice(index, 1)
      } else {
        this.Data.Variable[variable].splice(index, 1)
      }
    },
    OpenMediaStore(type = 'Carousel', multiple = true, index = false) {
      this.ChooseImageType = this.MediaStoreConfig.ChooseImageType = type
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = multiple
      if (index !== false) {
        this.ChooseImageIndex = index
      }
    },
    async SetImages (type = 'Carousel') {
      const storageInstance = this.$store.state.userProjectApp.storage()
      switch (type) {
        case 'Cover':
          this.SetSingleImage('Cover')
          break
        case 'Editor':
          document.getElementById('tinymce-image-input').value = await this.ResolveImageURL()
          document.getElementById('tinymce-image-input').dispatchEvent(new Event('change'));
          break
        default:
          if (typeof this.Data[type] === 'undefined') {
            this.$set(this.Data, type, [])
          }
          this.ChooseImageType = ''
            switch (typeof this.MediaStoreConfig.ChooseImageList) {
              case 'string':
                if (this.$store.state.user.permission.StaticDomain) {
                  if (this.NowChooseLanguage === 'default') {
                    this.$set(this.Data[type], this.ChooseImageIndex, {
                      URL: this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList,
                      Alt: ''
                    })
                    return true
                  }
                  this.$set(this.Data.LanguageData[this.NowChooseLanguage][type], this.ChooseImageIndex, {
                    URL: this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList,
                    Alt: ''
                  })
                } else {
                  storageInstance.ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
                    if (this.NowChooseLanguage === 'default') {
                      this.$set(this.Data[type], this.ChooseImageIndex, {URL, Alt: ''})
                      return true
                    }
                    this.$set(this.Data.LanguageData[this.NowChooseLanguage][type], this.ChooseImageIndex, {URL, Alt: ''})
                  })
                }
                break
              case 'object':
                if (Array.isArray(this.MediaStoreConfig.ChooseImageList)) {
                  this.MediaStoreConfig.ChooseImageList.forEach(imgPath => {
                    if (this.$store.state.user.permission.StaticDomain) {
                      if (this.NowChooseLanguage === 'default') {
                        this.Data[type].push({
                          URL: this.$store.state.user.permission.StaticDomain + imgPath,
                          Alt: ''
                        })
                        return true
                      }
                      this.Data.LanguageData[this.NowChooseLanguage][type].push({
                        URL: this.$store.state.user.permission.StaticDomain + imgPath,
                        Alt: ''
                      })
                    } else {
                      storageInstance.ref().child(imgPath).getDownloadURL().then((URL) => {
                        if (this.NowChooseLanguage === 'default') {
                          this.Data[type].push({
                            URL,
                            Alt: ''
                          })
                          return true
                        }
                        this.Data.LanguageData[this.NowChooseLanguage][type].push({
                          URL,
                          Alt: ''
                        })
                      })
                    }
                  })
                }
                break
            }
          break
      }
    },
    SetSingleImage(type, index) {
      if (this.$store.state.user.permission.StaticDomain) {
        if (this.NowChooseLanguage === 'default') {
          if (index) this.Data[type][index] = this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList
          else this.$set(this.Data, type, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
        } else {
          if (index) this.Data.LanguageData[this.NowChooseLanguage][type][index] = this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList
          else this.$set(this.Data.LanguageData[this.NowChooseLanguage], type, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
        }
      } else {
        this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
          if (this.NowChooseLanguage === 'default') {
            if (index) this.Data[type][index] = URL
            else this.$set(this.Data, type, URL)
          } else {
            if (index) this.Data.LanguageData[this.NowChooseLanguage][type][index] = URL
            else this.$set(this.Data.LanguageData[this.NowChooseLanguage], type, URL)
          }
        })
      }
    },
    ResolveImageURL () {
      if (this.$store.state.user.permission.StaticDomain) {
        return Promise.resolve(this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
      }
      return this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
        return URL
      })
    },
    OpenMediaMeta(type = 'Carousel', index = false) {
      if (index === false) {
        return false
      }
      if (this.NowChooseLanguage === 'default') {
        this.MediaMetaConfig.ImageMeta = this.Data[type][index]
      } else {
        this.MediaMetaConfig.ImageMeta = this.Data.LanguageData[this.NowChooseLanguage][type][index]
      }
      this.MediaMetaConfig.Display = !this.MediaMetaConfig.Display
      this.ChooseImageType = type
      this.ChooseImageIndex = index
    },
    SetMeta() {
      this.MediaMetaConfig.ImageMeta = {}
      this.ChooseImageType = ''
      this.ChooseImageIndex = false
      this.MediaMetaConfig.Display = false
    },
    RemoveImage(type = '', index) {
      if (this.NowChooseLanguage === 'default') {
        this.Data[type].splice(index, 1)
        return true
      }
      this.Data.LanguageData[this.NowChooseLanguage][type].splice(index, 1)
    },
    OpenVideoContentModal () {
      this.CurrentComponent = () => import('@/components/Video/VideoList')
      this.Toggle = true
    },
    OpenTicketScheduleModal () {
      this.CurrentComponent = () => import('@/components/product/TicketSchedule')
      this.Toggle = true
    },
    ChooseVideoContent (prop) {
      this.Data.Variable.RelateContent = {
        Name: prop[0].Name,
        Slug: prop[0].Slug,
        _id: prop[0]._id,
        ID: prop[0]._id,
        CreateTime: prop[0].CreateTime
      }
    },
    SetSchedule (prop) {
      this.Data.Variable.ScheduleList = [
        ...prop
      ]
    },
    ResetVideoContent () {
      this.Data.Variable.RelateContent = null
    },
    ChangeTab (tabName) {
      if (this.GoogleCategories.length === 0 && (tabName === 8 || tabName === 9)) {
        this.GetGoogleCategories()
      }
    },
    GetGoogleCategories () {
      return this.$store.dispatch('InnerRequest', {
        url: '/product/gmcProductCategories',
        method: 'get'
      }).then(({ data }) => {
        this.GoogleCategories = data.Data.map(item => {
          return {
            label: item.Category,
            value: item.ID
          }
        })
      }).catch((err) => {
        throw err
      })
    },
    InitLocaleData() {
      if (this.$store.state.user.permission.MutipleLocale) {
        if (!this.Data.LanguageData) {
          this.$set(this.Data, 'LanguageData', {})
        }
        this.$store.state.user.permission.Locales.forEach((locale) => {
          if (!this.Data.LanguageData[locale]) {
            this.$set(this.Data.LanguageData, locale, {
              Name: '',
              OtherName: '',
              Brand: '',
              Content: '<p></p>',
              Unit: '<p></p>',
              Tags: [],
              SEO: {
                Description: '',
                Keywords: ''
              },
              Gallery: [
                {URL: '', Alt: ''}
              ],
              Carousel: [
                {URL: '', Alt: ''}
              ],
              Cover: '',
              OverViewImages: [
                {URL: '', Alt: ''}
              ],
              HeaderText: '',
              'Variable-FeatureText': [],
              ResponseData: {
                Mobile: {
                  Content: '<p></p>',
                  Unit: '<p></p>',
                },
                Web: {
                  Content: '<p></p>',
                  Unit: '<p></p>',
                }
              }
            })
          }
        })
      }
      this.NowChooseLanguage = this.$store.state.user.permission.MutipleLocale ? this.$store.state.user.permission.DefaultLocale : 'default'
    },
    SynchronizeDefaultLocaleData() {
      const DefaultLocale = this.$store.state.user.permission.DefaultLocale
      if (DefaultLocale !== 'default') {
        this.Data.Name = this.Data.LanguageData[DefaultLocale].Name
        this.Data.OtherName = this.Data.LanguageData[DefaultLocale].OtherName
        this.Data.Brand = this.Data.LanguageData[DefaultLocale].Brand
        this.Data.HeaderText = this.Data.LanguageData[DefaultLocale].HeaderText
        this.Data.Content = this.Data.LanguageData[DefaultLocale].Content
        this.Data.Cover = this.Data.LanguageData[DefaultLocale].Cover
        this.Data.Unit = this.Data.LanguageData[DefaultLocale].Unit
        this.Data.SEO = this.Data.LanguageData[DefaultLocale].SEO
        this.Data.Carousel = this.Data.LanguageData[DefaultLocale].Carousel
        this.Data.Gallery = this.Data.LanguageData[DefaultLocale].Gallery
        this.Data.OverViewImages = this.Data.LanguageData[DefaultLocale].OverViewImages
        this.Data.Variable.FeatureText = this.Data.LanguageData[DefaultLocale]['Variable-FeatureText']
        this.Data.ResponseData = this.Data.LanguageData[DefaultLocale].ResponseData
      }
    },
    GetSKUList() {
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/service',
        url: '/product/availableList',
        method: 'post',
        data: {
          Project: this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project,
          Taxonomy: 'Stock'
        }
      }).then(({ data }) => {
        this.SKUList = data.Data.map(item => {
          return {
            label: `${item.Name} (SKU: ${item.CustomSeriesNum})`,
            value: item.CustomSeriesNum,
            SKU: item.CustomSeriesNum,
            Name_SKU: `${item.Name} (SKU: ${item.CustomSeriesNum})`
          }
        })
      }).catch((error) => {
        throw error
      })
    },
    ChangeSKU(value) {
      this.Data.Stock.SKU = value.SKU
    }
  }
}
</script>
